import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Pagination from 'react-js-pagination';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Card, CardBody, Col, Container, ListGroup, ListGroupItem, Row} from 'reactstrap';

import FilterMenu from '../../components/FilterMenu';
import PageLoader from '../../components/PageLoader';
import Text from '../../components/Text';
import queryString from '../../helpers/queryString';
import user from '../../helpers/user';
import history from '../../history';
import {availabilityActions, cartActions, priceActions, productSearchActions} from '../../store';

import ProductPreview from './ProductPreview';

class Search extends Component {  
  constructor(props){    
    super(props);

    this.perPage = 12;
    this.pageRange = 8;
    
    let values = queryString.parse();
    this.state = {
      queryString: window.location.search,
      currentPage: values.p ? parseInt(values.p) : 1,
      batterySystems: values.bs ? values.bs.split(',').map(x => x.toLowerCase()) : [],
      powerSources: values.ps ? values.ps.split(',').map(x => x.toLowerCase()) : [],
      categories: values.c ? values.c.split(',').map(x => x.toLowerCase()) : [],
      isEmployeeAvailable: values.ea ? values.ea : false,
    };
  }

  componentDidMount() {
    this.props.getIndexProducts(this.state.queryString, this.props.locale)
      .then(payload => {
        // User is attempting to navigate beyond last page; Redirect to last page
        if (this.state.currentPage > payload.totalPages) {
          history.replace(queryString.update('p', payload.totalPages));
          this.setState({
            ...this.state,
            currentPage: payload.totalPages
          });
        }
        if (user.canPlaceOrders()) {
          let skus = payload.data.map(product => product.sku);
          this.updatePrices(skus);
          this.updateAvailability(skus);
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps === undefined)
      return false;
    
    if (this.state.queryString !== window.location.search || prevProps.accountNumber !== this.props.accountNumber) {
      this.props.getIndexProducts(window.location.search, this.props.locale)
        .then(payload => {
          if (user.canPlaceOrders() && payload && payload.data) {
            let skus = payload.data.map(product => product.sku);
            this.updatePrices(skus);
            this.updateAvailability(skus);
          }
        });
      
      let values = queryString.parse();
      this.setState({
        ...this.state,
        queryString: window.location.search,
        currentPage: values.p ? parseInt(values.p) : 1,
        batterySystems: values.bs ? values.bs.split(',').map(x => x.toLowerCase()) : [],
        powerSources: values.ps ? values.ps.split(',').map(x => x.toLowerCase()) : [],
        categories: values.c ? values.c.split(',').map(x => x.toLowerCase()) : [],
        isEmployeeAvailable: values.ea ? values.ea : false,
      });
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });
    history.push(queryString.update('p', pageNumber));
    window.scrollTo(0, 0);
  };
  
  handleAddToCart = (product, quantity) => {
    const { addToCart, getCartStatistics, accountNumber, isEmployeeAccount, intl } = this.props;

    return addToCart(product, quantity, accountNumber, (!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount))
      .then(() => getCartStatistics(accountNumber))
      .then(() => { toastr.success(intl.formatMessage({ id: 'cart.addToCartToastTitle' }, { quantity }), intl.formatMessage({ id: 'cart.addToCartToastMessage' }, { quantity, description: this.props.configuration?.featureToggles?.UseProductOptionTitle && product.optionTitle?.length ? product.optionTitle : product.description }))})
      .catch(() => {toastr.error(intl.formatMessage({id: 'cart.notAddedToCartToastTitle'}, {quantity}), intl.formatMessage({id: 'cart.notAddedToCartToastMessage'}, {quantity}), {timeOut: 0})});
  };
  
  updatePrices = (skus) => {
    this.props.getPrices(skus, this.props.accountNumber);
  };
  
  updateAvailability = (skus) => {
    this.props.getAvailability(skus, this.props.accountNumber);
  };
  
  render() {
    const { products, totalCount, count, isLoading } = this.props.search.getIndexProducts;
    
    let results = products && products.map((product, index) => {
      return (
        <ListGroupItem key={`product-${index}`} data-test-id={`product-preview-details-${product.sku}`} className='search-list-item'>
          <ProductPreview
            product={product}
            pricing={this.props.pricing}
            availability={this.props.availability}
            onAddToCartClick={this.handleAddToCart}
            updatePrices={this.updatePrices}
            updateAvailability={this.updateAvailability}
            locale={this.props.match.params.locale}
            featureToggles={this.props.featureToggles}
            isEmployeeAccount={this.props.isEmployeeAccount}
          />
        </ListGroupItem>
      )
    });
    
    let blankResults = (
      <ListGroupItem className='text-center search-list-item'>
        <div className='my-4'>
          <FontAwesomeIcon icon='search' size='2x' className='mb-2 text-muted'/>
          <h3>No results found.</h3>
          <p data-test-id='cant-find-products-message' className='text-muted'>We can&apos;t find any products matching your search.</p>
        </div>
      </ListGroupItem>
    );
    
    const productList = isLoading ? <PageLoader/> : (totalCount > 0 ? results : blankResults);
    
    let resultsStart = totalCount > 0 ? (this.state.currentPage ? this.state.currentPage - 1 : 0) * this.perPage + 1 : 0;
    let resultsEnd = count < this.perPage ? totalCount : (this.state.currentPage ? this.state.currentPage : 1) * this.perPage;
    let resultsFromToOf = totalCount > 0 ? 'Showing ' + resultsStart + '-' + resultsEnd + ' of ' + totalCount : 'No';

    return (
      <div id='search' className='content-wrapper'>
        <Container>
          <Row className='my-3'>
            <Col data-test-id='showing-product-search-results'>
              <Text
                isHidden={this.props.search.getIndexProducts.isLoading}
                value={`${resultsFromToOf} results for "${queryString.parse().q || ''}"`
              }/>
            </Col>
          </Row>
          <Row>
            <Container>
              <Row>
                <Col md='2'>
                  <FilterMenu
                    powerSources={this.state.powerSources}
                    batterySystems={this.state.batterySystems}
                    categories={this.state.categories}
                    isEmployeeAvailable={this.state.isEmployeeAvailable}
                    isEmployeeAccount={this.props.isEmployeeAccount}
                    featureToggles={this.props.featureToggles}
                  />
                </Col>
                <Col>
                  <Card>
                    <CardBody className='p-0'>
                      <ListGroup data-test-id='product-search-list' className='search-list'>
                        {productList}
                      </ListGroup>
                    </CardBody>
                  </Card>
                  {!isLoading && totalCount > this.perPage && <Pagination
                    activePage={this.state.currentPage}
                    itemsCountPerPage={this.perPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={this.pageRange}
                    innerClass='pagination flex-wrap'
                    itemClass='page-item'
                    linkClass='page-link'
                    nextPageText={<FontAwesomeIcon icon='angle-right' />}
                    prevPageText={<FontAwesomeIcon icon='angle-left' />}
                    lastPageText={<FontAwesomeIcon icon='angle-double-right'/>}
                    firstPageText={<FontAwesomeIcon icon='angle-double-left'/>}
                    hideFirstLastPages={false}
                    onChange={this.handlePageChange}
                  />}
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    search: state.productSearch,
    pricing: state.pricing,
    availability: state.availability,
    locale: state.locales.locale,
    accountNumber: state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber,
    isEmployeeAccount: state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount : state.account.currentAccount.accountDetails.isEmployeeAccount,
    featureToggles: state.configuration.featureToggles
  };
};

const mapDispatchToProps = dispatch => ({
  getIndexProducts: (query, locale) => dispatch(productSearchActions.getIndexProducts(query, locale)),
  getPrices: (skus, accountNumber) => dispatch(priceActions.getPrices(skus, accountNumber)),
  getAvailability: (skus, accountNumber) => dispatch(availabilityActions.getAvailability(skus, accountNumber)),
  addToCart: (product, quantity, accountNumber, isEmployeeAccount) => dispatch(cartActions.addToCart(product, quantity, accountNumber, undefined, isEmployeeAccount)),
  getCartStatistics: (accountNumber) => dispatch(cartActions.getCartStatistics(accountNumber))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Search));