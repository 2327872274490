import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Button} from '../Button';

class ProductSearchResult extends Component {
  handleAddClick = () => {
    this.props.onAddClick(this.props.product);
  };
  
  render() {
    return this.props.product && (
      <Container fluid className='p-2'>
        <Row>
          <Col md='auto' xl='2' className='text-center mx-lg-2 my-lg-auto align-self-center'>
            <img src={`/api/v1/images?url=${this.props.product.imageUrl}`} alt={this.props.product.description}/>
          </Col>
          <Col className='is-color-red my-2 my-lg-auto mr-lg-1 align-self-center wrap-break-words'>
            <Container>
              <Row>
                <Col>
                  <div className='mb-2'>{this.props.product.skuAlias}</div>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.configuration?.featureToggles?.UseProductOptionTitle && this.props.product.optionTitle?.length ? this.props.product.optionTitle : this.props.product.description) }}
                    className='has-line-height-2 font-weight-bold' data-test-id='search-item-name'/>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md='auto' className='mb-2 my-lg-auto align-self-center'>
            <Container>
              <Row className='justify-content-center'>
                <Col xs='auto' className='mt-3 mt-xl-0'>
                  <Button outline color='primary' onClick={this.handleAddClick} isLoading={this.props.isProcessing}>Add</Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProductSearchResult.propTypes = {
  product: PropTypes.object,
  onAddClick: PropTypes.func,
  isProcessing: PropTypes.bool
};

export default ProductSearchResult;