import Enumerable from 'linq';
import React, { Component, Fragment } from 'react';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Lightbox from 'lightbox-react';
import Dropzone from 'react-dropzone';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import validator from 'validator';
import { Button, CardBodyField, Input, PageLoader, Price, ProductSearch } from '../../components';
import { getProfile } from '../../helpers/localStorage';
import user from '../../helpers/user';
import history from '../../history';
import { priceActions, productSearchActions, warrantyClaimActions } from '../../store';
import { downloadBlob } from '../../helpers/downloadblob';
import queryString from '../../helpers/queryString';
import PartsListTable from './PartsListTable';
import WarrantyClaimToggleArchivedModal from './WarrantyClaimToggleArchivedModal';

class WarrantyClaimDetails extends Component {
  constructor(props) {
    super(props);

    this.userProfile = getProfile();
    this.isApprover = user.canApproveWarrantyClaims();
    this.isCreator = user.canCreateWarrantyClaims();

    this.commentRegex = /(.|\s)*\S(.|\s)*/; // Any characters, at least one
    let query = queryString.parse();

    this.state = {
      claimType: '',
      laborRate: 0,
      laborTotal: 0,
      taxRate: 0,
      taxValue: 0,
      pstTaxRate: 0,
      pstTaxValue: 0,
      warrantyTotal: 0,
      receipt: [],
      parts: [],
      partsValidationErrors: [],
      partsTotal: 0,
      showAddPartModal: false,
      partsHaveChanges: false,
      isUpdating: false,
      isApproving: false,
      isDenying: false,
      isRejecting: false,
      isResubmitting: false,
      freightAllowance: {
        value: 0,
        isValid: false,
        hasChanges: false
      },
      hours: {
        value: 0,
        isValid: false,
        hasChanges: false
      },
      comments: [],
      newComment: {
        value: '',
        isValid: true
      },
      trySubmit: false,
      tryDownloadMemo: false,
      isImagesLightboxOpen: false,
      isNewClaim: Object.prototype.hasOwnProperty.call(query, 'newClaim'),
      isActionsDropdownOpen: false,
      isToggleArchivedModalOpen: false,
      isArchived: false,
      isArchiving: false
    };
  }


  handleUploadReceipt = (files) => {
    this.setState({
      ...this.state,
      receipt: files
    });
  };

  componentDidMount() {
    this.getClaim();
  }

  validateFreightAllowance = (value) => {
    return value !== undefined ? validator.isCurrency(value.toString()) : false;
  };

  validateHours = (value) => {
    return value !== undefined ? validator.isDecimal(value.toString()) : false;
  };

  validateComment = (value) => {
    let isValidComment = value !== undefined ? new RegExp(this.commentRegex).test(value) : false;
    this.setState({
      ...this.state,
      newComment: {
        ...this.state.newComment,
        isValid: isValidComment
      }
    });
    return isValidComment;
  };

  validate = (commentRequired = true) => {
    let freightAllowanceIsValid = this.validateFreightAllowance(this.state.freightAllowance.value);
    let hoursIsValid = this.validateHours(this.state.hours.value);
    let commentIsValid = commentRequired ? this.validateComment(this.state.newComment.value) : true;

    return freightAllowanceIsValid && hoursIsValid && commentIsValid;
  };

  hideFreightAllowance = () => {
    let { receivedVia, returnedVia } = this.props.warrantyClaim.getClaim.claim;
    const customerDropoffOption = 2;
    return receivedVia === customerDropoffOption && returnedVia === customerDropoffOption;
  };

  reviewWarrantyClaim = (status) => {
    const { claim } = this.props.warrantyClaim.getClaim;
    let files = [];
    if (status === 'PendingSubsequentReview' && this.state.receipt) {
      files.push({ 'name': 'receipt', 'file': this.state.receipt[0] });
    }
    this.props.reviewWarrantyClaim({
      id: claim.id,
      comment: this.state.newComment.value,
      status,
      laborHours: this.state.hours.value,
      freightAllowance: this.state.freightAllowance.value,
      havePartsChanged: false,
      accountNumber: this.props.accountNumber
    }, files
    ).then(payload => {
      if (payload.errorCode) {
        toastr.error(this.props.intl.formatMessage({ id: 'warranty.review.errorTitle' }),
          this.props.intl.formatMessage({ id: 'warranty.review.' + payload.errorCode }, {
            oldStatus: this.props.intl.formatMessage({ id: 'warranty.claimStatus.' + payload.oldStatus }),
            newStatus: this.props.intl.formatMessage({ id: 'warranty.claimStatus.' + payload.newStatus })
          }), { timeOut: 0 });
      }
      else {
        history.goBack();
      }

      this.setState({
        ...this.state,
        isUpdating: false
      });
    });
  };

  getClaim = () => {
    this.props.getClaim(this.props.match.params.id, this.props.accountNumber)
      .then(payload => {
        if (payload && payload.data) {
          const isArchived = !!payload.data.deleted;
          if (isArchived && !this.userCanArchivedClaims()) {
            return history.replace('/NotFound');
          }

          this.setState({
            ...this.state,
            claimType: payload.data.claimType,
            laborRate: payload.data.laborRate,
            laborTotal: this.calculateLaborTotal(payload.data.hours, payload.data.laborRate),
            taxRate: payload.data.taxRate,
            taxValue: payload.data.taxValue,
            pstTaxRate: payload.data.pstTaxRate ? payload.data.pstTaxRate : 0,
            pstTaxValue: payload.data.pstTaxValue ? payload.data.pstTaxValue : 0,
            warrantyTotal: this.calculateWarrantyTotal(payload.data.hours, payload.data.laborRate, payload.data.freightAllowance, payload.data.partsTotal, payload.data.taxValue, payload.data.pstTaxValue),
            parts: payload.data.parts,
            partsTotal: payload.data.partsTotal,
            freightAllowance: {
              value: payload.data.freightAllowance,
              hasChanges: false,
              isValid: this.validateFreightAllowance(payload.data.freightAllowance)
            },
            hours: {
              value: payload.data.hours,
              hasChanges: false,
              isValid: this.validateHours(payload.data.hours)
            },
            comments: payload.data.commentLog,
            newComment: {
              value: '',
              isValid: false
            },
            trySubmit: false,
            isArchived
          });
        }
      });
  };

  getAutoApprovalViolation = (id) => {
    var idParts = id.split(':');
    switch (idParts[0]) {
      case 'PartValueExceedsThreshold':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.valueThreshold' });
      case 'LaborHoursExceedsThreshold':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.laborHoursThreshold' });
      case 'ContainsObsoleteItem':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.obsoleteItem' });
      case 'RepairDurationExceedsThreshold':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.repairDurationThreshold' });
      case 'PartQuantityExceedsThreshold':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.partQuantityThreshold' });
      case 'FreightAllowanceExceedsThreshold':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.freightAllowanceThreshold' });
      case 'AlwaysReviewSkuPrefix':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.reviewSkuPrefix' });
      case 'AlwaysReviewDefectivePartClaim':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.reviewDefectivePartClaim' });
      case 'DiscontinuedPart':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.discontinuedPart' }, { itemNumber: idParts[1] })
      case 'ReplacedPart':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.replacementPart' }, { itemNumber: idParts[1], replacementSku: idParts[2] })
      case 'BackOrdedPart':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.backOrderedPart' }, { itemNumber: idParts[1] })
      case 'BackOrdedPartWithDate':
        return this.props.intl.formatMessage({ id: 'warranty.autoApprovalViolation.backOrderedPartWithDate' }, { itemNumber: idParts[1], date: idParts[2] })
      default:
        return '';
    }
  };

  getShippedType = (id) => {
    switch (id) {
      case 1:
        return this.props.intl.formatMessage({ id: 'warranty.shippedType.notAvailable' });
      case 2:
        return this.props.intl.formatMessage({ id: 'warranty.shippedType.customerDropOffOrPickup' });
      case 3:
        return this.props.intl.formatMessage({ id: 'warranty.shippedType.companyTruck' });
      case 4:
        return this.props.intl.formatMessage({ id: 'warranty.shippedType.carrierFreight' });
      default:
        return '';
    }
  };

  getShippingProvider = (id) => {
    switch (id) {
      case 1:
        return 'FedEx';
      case 2:
        return 'UPS';
      case 3:
        return 'USPS';
      case 4:
        return 'Purolator';
      case 5:
        return 'UPSFreight';
      case 6:
        return 'SAIA';
      case 7:
        return 'USFHolland';
      case 8:
        return 'YRC';
      case 9:
        return 'Southeastern';
      default:
        return '';
    }
  }

  getTrackingLink = (trackingNumber, trackingProvider) => {
    let link = '';
    switch (trackingProvider) {
      case 1:
        link = `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${trackingNumber}`;
        return <a href={link} target='_blank' rel='noopener noreferrer'>{trackingNumber} <FontAwesomeIcon className='icon-link ml-2' icon='external-link-alt' /></a>
      case 2:
      case 5:
        link = `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
        return <a href={link} target='_blank' rel='noopener noreferrer'>{trackingNumber} <FontAwesomeIcon className='icon-link ml-2' icon='external-link-alt' /></a>
      case 3:
        link = `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`;
        return <a href={link} target='_blank' rel='noopener noreferrer'>{trackingNumber} <FontAwesomeIcon className='icon-link ml-2' icon='external-link-alt' /></a>
      case 4:
        link = `https://www.purolator.com/purolator/ship-track/tracking-details.page?pin=${trackingNumber}`;
        return <a href={link} target='_blank' rel='noopener noreferrer'>{trackingNumber} <FontAwesomeIcon className='icon-link ml-2' icon='external-link-alt' /></a>
      case 7:
        link = `http://public.hollandregional.com/shipmentStatus/Track?proNumber=${trackingNumber}`;
        return <a href={link} target='_blank' rel='noopener noreferrer'>{trackingNumber} <FontAwesomeIcon className='icon-link ml-2' icon='external-link-alt' /></a>
      case 8:
        link = `https://my.yrc.com/tools/track/shipments?referenceNumber=${trackingNumber}&referenceNumberType=PRO`;
        return <a href={link} target='_blank' rel='noopener noreferrer'>{trackingNumber} <FontAwesomeIcon className='icon-link ml-2' icon='external-link-alt' /></a>
      default:
        return <Fragment>{trackingNumber}</Fragment>;
    }
  }

  getClaimStatus = (id) => {
    switch (id) {
      case 1:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.draft' });
      case 2:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.pendingInitialReview' });
      case 3:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.approved' });
      case 4:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.approvedWithChanges' });
      case 5:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.rejected' });
      case 6:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.pendingSubsequentReview' });
      case 7:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.denied' });
      case 8:
        return this.props.intl.formatMessage({ id: 'warranty.claimStatus.pendingAutoApproval' });
      default:
        return '';
    }
  };

  calculateLaborTotal = (hours, rate) => {
    return (!isNaN(hours) ? Number(hours) : 0) * (!isNaN(rate) ? Number(rate) : 0);
  };

  calculateWarrantyTotal = (hours, laborRate, freightAllowance, partsTotal, taxValue, pstTaxValue) => {
    return this.calculateLaborTotal(hours, laborRate) + (!isNaN(freightAllowance) ? Number(freightAllowance) : 0) + (!isNaN(partsTotal) ? Number(partsTotal) : 0) + (!isNaN(taxValue) ? Number(taxValue) : 0) + (!isNaN(pstTaxValue) ? Number(pstTaxValue) : 0);
  };

  handleFreightAllowanceChange = (obj) => {
    const { claim } = this.props.warrantyClaim.getClaim;

    let isValid = this.validateFreightAllowance(obj.value);

    let hasChanges = (claim.freightAllowance.toString() !== obj.value);

    let isValidComment = (!this.state.partsHaveChanges && !hasChanges && !this.state.hours.hasChanges) || (this.state.newComment.value.length > 0);
    this.setState({
      ...this.state,
      freightAllowance: {
        value: obj.value,
        hasChanges,
        isValid
      },
      newComment: {
        ...this.state.newComment,
        isValid: isValidComment
      },
      warrantyTotal: this.calculateWarrantyTotal(this.state.hours.value, claim.laborRate, obj.value, this.state.partsTotal, this.state.taxValue, this.state.pstTaxValue)
    });
  };

  handleHoursChange = (obj) => {
    const { claim } = this.props.warrantyClaim.getClaim;
    let isValid = this.validateHours(obj.value);
    let hasChanges = (claim.hours.toString() !== obj.value);
    let isValidComment = (!this.state.partsHaveChanges && !this.state.freightAllowance.hasChanges && !hasChanges) || (this.state.newComment.value.length > 0);
    this.setState({
      ...this.state,
      hours: {
        value: obj.value,
        hasChanges,
        isValid
      },
      newComment: {
        ...this.state.newComment,
        isValid: isValidComment
      },
      laborTotal: this.calculateLaborTotal(obj.value, this.state.laborRate),
      warrantyTotal: this.calculateWarrantyTotal(obj.value, this.state.laborRate, this.state.freightAllowance.value, this.state.partsTotal, this.state.taxValue, this.state.pstTaxValue)
    });
  };

  handleQuantityChange = (quantity, index) => {
    let parts = this.state.parts;
    let part = parts[index];
    part.quantity = quantity;

    this.setState({
      ...this.state,
      partsHaveChanges: true,
      parts
    });
  };

  handlePartLineTypeChange = (partLineType, index) => {
    let parts = this.state.parts;
    let part = parts[index];
    part.partLineType = partLineType; // This is the ID of the PartLineType

    this.setState({
      ...this.state,
      partsHaveChanges: true,
      parts
    });
  };

  handleNewCommentChange = (obj) => {
    this.setState({
      ...this.state,
      newComment: {
        value: obj.value,
        isValid: obj.isValid
      }
    });
  };

  handleDeleteClick = (index) => {
    let parts = this.state.parts;
    let partsValidationErrors = this.state.partsValidationErrors;
    let validationErrorsToKeep = Enumerable.from(partsValidationErrors)
      .where(x => x.itemNumber !== parts[index].itemNumber)
      .toArray();

    parts.splice(index, 1);

    this.setState({
      ...this.state,
      partsHaveChanges: true,
      parts,
      partsValidationErrors: validationErrorsToKeep
    });
  };

  handleAddPartClick = (part) => {
    let parts = this.state.parts;
    let existingPart = Enumerable.from(parts)
      .where(x => x.itemNumber === part.skuAlias)
      .firstOrDefault();

    if (!existingPart) {
      let newPart = {
        id: undefined,
        isValid: false,
        isActive: true,
        quantity: 1,
        listPrice: undefined,
        unitPrice: undefined,
        description: part.description,
        itemNumber: part.skuAlias,
        partLineType: 1
      };

      parts.push(newPart);
    }
    else {
      existingPart = {
        ...existingPart,
        quantity: existingPart.quantity++
      };
    }

    this.setState({
      ...this.state,
      partsHaveChanges: true,
      parts
    });
  };

  handleSaveChangesClick = () => {
    this.setState({
      ...this.state,
      isUpdating: true
    }, () => {
      const { claim } = this.props.warrantyClaim.getClaim;

      this.props.updateParts(claim.id, this.state.parts, this.props.accountNumber, this.state.newComment.value)
        .then(payload => {
          this.setState({
            ...this.state,
            taxValue: payload.data.taxValue,
            pstTaxValue: payload.data.pstTaxValue ? payload.data.pstTaxValue : 0,
            isUpdating: false,
            partsHaveChanges: false,
            parts: payload.data.parts,
            partsValidationErrors: payload.data.errorMessages,
            partsTotal: payload.data.partsTotal,
            comments: payload.data.commentLog,
            newComment: {
              value: '',
              isValid: true
            },
            warrantyTotal: this.calculateWarrantyTotal(this.state.hours.value, this.state.laborRate, this.state.freightAllowance.value, payload.data.partsTotal, payload.data.taxValue, payload.data.pstTaxValue)
          });
        });
    });
  };

  handleDenyClick = () => {
    this.setState({
      ...this.state,
      trySubmit: true
    }, () => {
      if (this.validate()) {
        this.setState({
          ...this.state,
          isUpdating: true,
          isDenying: true
        }, () => {
          this.reviewWarrantyClaim('Denied');
        });
      }
    });
  };

  handleRejectClick = () => {
    this.setState({
      ...this.state,
      trySubmit: true
    }, () => {
      if (this.validate()) {
        this.setState({
          ...this.state,
          isUpdating: true,
          isRejecting: true
        }, () => {
          this.reviewWarrantyClaim('Rejected');
        });
      }
    });
  };

  handleApproveClick = () => {
    this.setState({
      ...this.state,
      trySubmit: true
    }, () => {
      if (this.validate((this.state.partsHaveChanges || this.state.freightAllowance.hasChanges || this.state.hours.hasChanges))) {
        this.setState({
          ...this.state,
          isUpdating: true,
          isApproving: true
        }, () => {
          this.reviewWarrantyClaim('Approved');
        });
      }
    });
  };

  handleResubmitClick = () => {
    this.setState({
      ...this.state,
      trySubmit: true
    }, () => {
      if (this.validate()) {
        this.setState({
          ...this.state,
          isUpdating: true,
          isResubmitting: true
        }, () => {
          this.reviewWarrantyClaim('PendingSubsequentReview');
        });
      }
    });
  };

  handleAddCommentClick = () => {
    const { claim } = this.props.warrantyClaim.getClaim;

    this.setState({
      ...this.state,
      isUpdating: true
    }, () => {
      if (this.validateComment(this.state.newComment.value)) {
        this.props.addComment(claim.id, this.state.newComment.value, this.props.accountNumber)
          .then(payload => {
            if (payload.errorCode) {
              toastr.error(this.props.intl.formatMessage({ id: 'warranty.newComment.errorTitle' }), this.props.intl.formatMessage({ id: 'warranty.newComment.errorMessage' }), { timeOut: 0 });
            }
            else {
              let addedComment = {
                username: payload.data.username,
                date: payload.data.date,
                status: payload.data.status,
                comments: payload.data.comments
              };

              let comments = this.state.comments;
              comments.push(addedComment);

              this.setState({
                ...this.state,
                isUpdating: false,
                newComment: {
                  value: '',
                  isValid: false
                },
                comments
              });
            }
          });
      }
    });
  };

  toggleAddPartModal = () => {
    this.props.clearIndexProducts();

    this.setState({
      ...this.state,
      showAddPartModal: !this.state.showAddPartModal
    });
  };

  getCreditMemo = () => {
    this.setState({
      ...this.state,
      tryDownloadMemo: true
    }, () => {
      const { claim } = this.props.warrantyClaim.getClaim;
      this.props.getCreditMemo(claim.id, this.props.accountNumber)
        .then((blob) => {
          downloadBlob('Credit Memo.pdf', blob);
          this.setState({
            ...this.state,
            tryDownloadMemo: false
          });
        });
    });
  }

  getReceipt = () => {
    const { claim } = this.props.warrantyClaim.getClaim;
    this.props.getFile(claim.receipt.id, claim.id)
      .then((blob) => {
        downloadBlob(claim.receipt.fileName, blob);
      });
  }

  getShippingLabel = () => {
    const { claim } = this.props.warrantyClaim.getClaim;
    this.props.getFile(claim.shipmentFile.id, claim.id)
      .then((blob) => {
        downloadBlob(claim.shipmentFile.fileName, blob);
      });
  }

  openImagesLightbox = (index) => {
    this.setState({
      ...this.state,
      isImagesLightboxOpen: true,
      photoIndex: index
    });
  }

  handleNewClaimClick = () => {
    history.push('/warranty/newClaim');
  }

  toggleActionsDropdown = () => {
    this.setState({
      ...this.state,
      isActionsDropdownOpen: !this.state.isActionsDropdownOpen
    });
  };

  openToggleArchivedModal = () => {
    this.setState({
      ...this.state,
      isToggleArchivedModalOpen: true
    });
  };

  closeToggleArchivedModal = () => {
    this.setState({
      ...this.state,
      isToggleArchivedModalOpen: false
    });
  };

  handleToggleArchivedClick = () => {
    const { claimNumber, id } = this.props.warrantyClaim.getClaim.claim;

    this.setState({
      ...this.state,
      isArchiving: true
    }, () => {
      this.props.toggleDeleted(id)
        .then(() => {
          const toastTitle = `warranty.archive.${this.state.isArchived ? 'unarchiveSuccessToastTitle' : 'archiveSuccessToastTitle'}`;
          const toastMessage = `warranty.archive.${this.state.isArchived ? 'unarchiveSuccessToastMessage' : 'archiveSuccessToastMessage'}`;
          toastr.success(
            this.props.intl.formatMessage({ id: toastTitle }),
            this.props.intl.formatMessage({ id: toastMessage }, { claimNumber }),
            { timeOut: 5000 }
          );

          this.setState({
            ...this.state,
            isArchived: !this.state.isArchived,
            isActionsDropdownOpen: false,
            isArchiving: false,
            isToggleArchivedModalOpen: false
          });
        })
        .catch(() => {
          const toastMessage = `warranty.archive.${this.state.isArchived ? 'unarchiveErrorToastMessage' : 'archiveErrorToastMessage'}`;
          toastr.error(
            this.props.intl.formatMessage({ id: 'warranty.archive.errorToastTitle' }),
            this.props.intl.formatMessage({ id: toastMessage }),
            { timeOut: 5000 }
          );

          this.setState({
            ...this.state,
            isArchiving: false,
          });
        })
    });
  };

  userCanArchivedClaims = () => {
    return user.isMilwaukeeToolEmployee() && user.canApproveWarrantyClaims();
  }

  render() {
    const { claim, isLoading } = this.props.warrantyClaim.getClaim;
    const { isImagesLightboxOpen, photoIndex } = this.state;

    if (isLoading && !claim) {
      return <PageLoader />;
    }

    if (!isLoading && !claim) {
      return (
        <ListGroup>
          <ListGroupItem className='text-center'>
            <div className='my-4'>
              <h3>Details are not available for this Claim</h3>
            </div>
          </ListGroupItem>
        </ListGroup>
      );
    }

    const customerCountry = claim.customerCountry.toUpperCase();
    const isCompleted = (claim.status === 3 || claim.status === 4 || claim.status === 7);
    const canApproverEdit = (claim.status === 2 || claim.status === 6 || claim.status === 8);

    const canEdit = (this.isApprover && canApproverEdit)
      || (this.isCreator && (claim.status === 1 || claim.status === 5));

    let autoApproveViolationsList = claim.nonAutoApprovalReasons.map((reasonId, index) => {
      let reason = this.getAutoApprovalViolation(reasonId);

      return (
        <li key={`violation-${index}`}>
          {reason}
        </li>
      );
    });

    let autoApproveViolationsMarkup = this.isApprover && (
      <Card>
        <CardHeader>
          <span className='text-uppercase'><FormattedMessage id='warranty.autoApprovalViolation.initialViolation' /></span>
        </CardHeader>
        <CardBody>
          <ul className='mb-0'>{autoApproveViolationsList}</ul>
        </CardBody>
      </Card>
    );

    let saveChangesButtonMarkup = this.state.partsHaveChanges && (
      <Button color='primary' onClick={this.handleSaveChangesClick} className='mr-2' isLoading={this.state.isUpdating}><FormattedMessage id='warranty.button.saveChanges' /></Button>
    );

    let addPartButtonMarkup = canEdit && (
      <Button className='d-print-none' color='secondary' onClick={this.toggleAddPartModal}><FormattedMessage id='warranty.button.addPart' /></Button>
    );

    let partsListMarkup = this.state.parts && (
      <Fragment>
        <Row>
          <Col>
            <ListGroup className='mt-3'>
              <PartsListTable
                parts={this.state.parts}
                validationErrors={this.state.partsValidationErrors}
                linesLimit={10}
                onQuantityChange={this.handleQuantityChange}
                onPartLineTypeChange={this.handlePartLineTypeChange}
                onDeleteClick={this.handleDeleteClick}
                canEdit={canEdit}
                showPrices={!(claim.pricingStructure === 0)}
              />
            </ListGroup>
          </Col>
        </Row>
        <Row className='justify-content-end mt-3 mt-lg-0'>
          <Col xs='auto'>
            {saveChangesButtonMarkup}
            {addPartButtonMarkup}
          </Col>
        </Row>
      </Fragment>
    );

    let addPartModal = (
      <Modal className='d-print-none' isOpen={this.state.showAddPartModal} toggle={this.toggleAddPartModal} centered={true}>
        <ModalHeader toggle={this.toggleAddPartModal}><FormattedMessage id='warranty.button.addPart' /></ModalHeader>
        <ModalBody>
          <ProductSearch onAddClick={this.handleAddPartClick} clear={this.state.showAddPartModal} isProcessing={this.state.isProcessing} configuration={this.props.configuration} />
        </ModalBody>
      </Modal>
    );

    let commentsList = this.state.comments && this.state.comments.length > 0 ? this.state.comments.map((comment, index) => {
      return (
        <ListGroupItem key={`claim-comment-${index}`}>
          <Container fluid className='my-2'>
            <Row>
              <Col>
                <span className='font-weight-bold'>{comment.username}</span>
              </Col>
              <Col className='text-sm-right'>
                <span className='has-font-size-1'><FormattedDate value={comment.date} /></span>
              </Col>
            </Row>
            <Row className='my-2'>
              <Col className='font-italic'>{this.getClaimStatus(comment.status)}</Col>
            </Row>
            <Row>
              <Col>
                <div>{comment.comments}</div>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
      )
    }) : <span className='font-italic'>There&apos;s no comments yet</span>;

    let photos = claim.photos && claim.photos.length > 0 && claim.photos.map((photo, index) => {
      return (
        <Col key={index} index={`photo-${index}`} xs='12' md='auto' className='text-center mx-lg-2 my-md-auto align-self-center photos' onClick={() => this.openImagesLightbox(index)}>
          <img src={`/warrantyapi/file/?warrantyClaimId=${claim.id}&hashedFile=${photo.id}`} alt={photo.fileName} />
        </Col>
      )
    });

    let lightboxImages = claim.photos && claim.photos.length > 0 && claim.photos.map(photo => {
      return `/warrantyapi/file/?warrantyClaimId=${claim.id}&hashedFile=${photo.id}`;
    });

    let addCommentButtonMarkup = !isCompleted && (this.isCreator || (this.isApprover && canApproverEdit)) && (
      <Row className='justify-content-end mt-4 d-print-none'>
        {this.state.isNewClaim &&
          <Col xs='auto'>
            <Button onClick={this.handleNewClaimClick}><FormattedMessage id='warranty.button.newClaim' /></Button>
          </Col>
        }
        <Col xs='auto'>
          <Button color='primary' onClick={this.handleAddCommentClick} className='d-print-none' isDisabled={!this.state.newComment.isValid} isLoading={this.state.isResubmitting}>
            <FormattedMessage id='warranty.button.addComment' />
          </Button>
          {this.isCreator && claim.status === 5 &&
            <Button color='primary' onClick={this.handleResubmitClick} className='d-print-none ml-2' isDisabled={this.state.partsHaveChanges || this.state.partsValidationErrors.length > 0 || !this.state.newComment.isValid} isLoading={this.state.isResubmitting}>
              <FormattedMessage id='warranty.button.resubmit' />
            </Button>
          }
        </Col>
      </Row>
    );

    let commentsMarkup = (
      <Fragment>
        <Row className='mt-3'>
          <Col>
            <div className='mb-2 has-font-size-2 font-weight-bold'><FormattedMessage id='general.comments' />:</div>
            <ListGroup>
              {commentsList}
            </ListGroup>
            {!isCompleted && (this.isCreator || (this.isApprover && canApproverEdit)) &&
              <Input
                type='textarea'
                placeholder={this.props.intl.formatMessage({ id: 'warranty.commentsPlaceholder' })}
                value={this.state.newComment.value}
                isValid={this.state.newComment.isValid}
                validationRegex={this.commentRegex}
                validationMessage='Please enter a valid comment'
                trySubmit={this.state.trySubmit}
                onChange={this.handleNewCommentChange}
                className='mt-3 additional-comments d-print-none'
              />
            }
          </Col>
        </Row>
        {addCommentButtonMarkup}
      </Fragment>
    );

    let ascAddressMarkup = (
      <div>
        <div>{claim.address1}</div>
        <div>{claim.address2}</div>
        <div>{claim.city + ', ' + claim.stateProvince + ' ' + claim.postalCode}</div>
      </div>
    );

    let customerAddressMarkup = (
      <div>
        <div>{claim.customerAddress1}</div>
        <div>{claim.customerAddress2}</div>
        <div>{claim.customerCity + ', ' + claim.customerStateProvince + ' ' + claim.customerPostalCode}</div>
      </div>
    );

    let taxValueMarkup = (customerCountry === "CANADA") ?
      <>
        <CardBodyField label='GST/HST:' value={<Price value={this.state.taxValue} canBeZero />} />
        <CardBodyField label='PST/QST:' value={<Price value={this.state.pstTaxValue} canBeZero />} />
      </>
      :
      <CardBodyField label='Tax:' value={<Price value={this.state.taxValue} canBeZero />} />
      ;

    let approverStatusChangeButtonsMarkup = this.isApprover && canApproverEdit && (
      <Row className='justify-content-end mt-4'>
        <Col xs='auto'>
          <Button color='primary' onClick={this.handleDenyClick} className='d-print-none mr-2' isDisabled={this.state.partsHaveChanges || this.state.partsValidationErrors.length > 0} isLoading={this.state.isDenying}>
            <FormattedMessage id='warranty.button.deny' />
          </Button>
          <Button color='primary' onClick={this.handleRejectClick} className='d-print-none mr-2' isDisabled={this.state.partsHaveChanges || this.state.partsValidationErrors.length > 0} isLoading={this.state.isRejecting}>
            <FormattedMessage id='warranty.button.reject' />
          </Button>
          <Button color='primary' onClick={this.handleApproveClick} className='d-print-none' isDisabled={this.state.partsHaveChanges || this.state.partsValidationErrors.length > 0} isLoading={this.state.isApproving}>
            <FormattedMessage id='warranty.button.approve' />
          </Button>
        </Col>
      </Row>
    );

    let creditMemoMarkup = (
      <Fragment>
        {claim.creditMemoNumber} <br />
        <Button color='primary' isLoading={this.state.tryDownloadMemo} onClick={this.getCreditMemo}>
          <FormattedMessage id='warranty.button.download' />
        </Button>
      </Fragment>
    );

    return (
      <div id='warranty-claim-details' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody className='header-body'>
                  <div data-test-id='warranty-claim-number' className='font-weight-bold'>Claim # {claim.claimNumber}</div>
                  {this.userCanArchivedClaims() && <Dropdown data-test-id='nav-support' isOpen={this.state.isActionsDropdownOpen} toggle={this.toggleActionsDropdown}>
                    <DropdownToggle nav>
                      <FontAwesomeIcon icon='ellipsis-vertical' className='actions-menu' />
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                      <div className='actions-option' onClick={this.openToggleArchivedModal}>
                        {this.state.isArchived ? 'Unarchive' : 'Archive'}
                      </div>
                    </DropdownMenu>
                  </Dropdown>}
                </CardBody>
              </Card>
              <WarrantyClaimToggleArchivedModal
                isArchived={this.state.isArchived}
                isOpen={this.state.isToggleArchivedModalOpen}
                onConfirm={this.handleToggleArchivedClick}
                close={this.closeToggleArchivedModal}
                isLoading={this.state.isArchiving}
              />
            </Col>
          </Row>
          <Row>
            <div className='col-lg-8 d-print-none'>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>
                    <FormattedMessage id='warranty.claimInformation' />
                  </span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md='8'>
                        <CardBodyField claimDataTestId='claim-details-claim-number' label='Claim #:' value={claim.claimNumber} />
                        <CardBodyField label='Status:' value={this.getClaimStatus(claim.status)} />
                        <CardBodyField label='Credit Memo #:' value={(claim.creditMemoNumber) ? creditMemoMarkup : <Fragment>{claim.claimNumber}</Fragment>} />
                        <CardBodyField label='Work Order #:' value={claim.workOrderNumber} />
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
              {autoApproveViolationsMarkup}
              {claim.claimType === 'Customer' &&
                <Card className='expense-summary'>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.expenseSummary' />
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Container fluid>
                      <Row>
                        <Col md='8'>
                          {!(claim.pricingStructure === 0) &&
                            <Fragment>
                              {!this.hideFreightAllowance() &&
                                <CardBodyField label='Freight Allowance:' value={canEdit ?
                                  <InputGroup className='freight-allowance'>
                                    <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                                    <Input
                                      type='text'
                                      value={this.state.freightAllowance.value}
                                      onChange={this.handleFreightAllowanceChange}
                                      validationMessage='Please enter a valid amount'
                                      isValid={this.state.freightAllowance.isValid}
                                      trySubmit={this.state.trySubmit}
                                    />
                                  </InputGroup> : <Price value={this.state.freightAllowance.value} canBeZero />
                                } />
                              }
                              <CardBodyField label='Labor Rate:' value={this.state.laborRate && <Price value={this.state.laborRate} canBeZero />} />
                              <CardBodyField label='Hours:' value={canEdit ?
                                <Input
                                  type='text'
                                  value={this.state.hours.value}
                                  onChange={this.handleHoursChange}
                                  validationMessage='Please enter a valid amount'
                                  isValid={this.state.hours.isValid}
                                  trySubmit={this.state.trySubmit}
                                  className='hours'
                                /> : <Fragment>{this.state.hours.value}</Fragment>
                              } />
                              <CardBodyField label='Total Labor:' value={<Price value={this.state.laborTotal} canBeZero />} />
                              <CardBodyField label='Parts:' value={this.state.partsTotal && <Price value={this.state.partsTotal} canBeZero />} />
                              {taxValueMarkup}
                              <hr />
                              <CardBodyField label='Warranty Total:' value={<Price value={this.state.warrantyTotal} canBeZero />} />
                            </Fragment>
                          }
                          {claim.pricingStructure === 0 &&
                            <Fragment>
                              <CardBodyField label='Labor:' value={<Price value={claim.flatRatePrice} canBeZero />} />
                              {taxValueMarkup}
                              <hr />
                              <CardBodyField label='Warranty Total:' value={<Price value={claim.flatRatePrice + this.state.taxValue + (customerCountry === "CANADA" ? this.state.pstTaxValue : 0)} canBeZero />} />
                            </Fragment>
                          }
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              }
              {claim.comments &&
                <Card>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.repairNotes' />
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md='8'>
                        {claim.comments}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              }
            </div>
            <div className='col d-print-block d-none'>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>
                    <FormattedMessage id='warranty.claimInformation' />
                  </span>
                </CardHeader>
                <CardBody>
                  <CardBodyField claimDataTestId='claim-details-claim-number' label='Claim #:' value={claim.claimNumber} />
                  <CardBodyField label='Status:' value={this.getClaimStatus(claim.status)} />
                  <CardBodyField label='Credit Memo #:' value={(claim.creditMemoNumber) ? creditMemoMarkup : <Fragment>{claim.claimNumber}</Fragment>} />
                  <CardBodyField label='Work Order #:' value={claim.workOrderNumber} />
                </CardBody>
              </Card>
              {claim.comments &&
                <Card>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.repairNotes' />
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md='8'>
                        {claim.comments}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              }
            </div>
            <div className='col d-print-block d-none'>
              {autoApproveViolationsMarkup}
              {claim.claimType === 'Customer' &&
                <Card className='expense-summary'>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.expenseSummary' />
                    </span>
                  </CardHeader>
                  <CardBody>
                    {!(claim.pricingStructure === 0) &&
                      <Fragment>
                        {!this.hideFreightAllowance() &&
                          <CardBodyField label='Freight Allowance:' value={canEdit ?
                            <InputGroup className='freight-allowance'>
                              <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                              <Input
                                type='text'
                                value={this.state.freightAllowance.value}
                                onChange={this.handleFreightAllowanceChange}
                                validationMessage='Please enter a valid amount'
                                isValid={this.state.freightAllowance.isValid}
                                trySubmit={this.state.trySubmit}
                              />
                            </InputGroup> : <Price value={this.state.freightAllowance.value} canBeZero />
                          } />
                        }
                        <CardBodyField label='Labor Rate:' value={this.state.laborRate && <Price value={this.state.laborRate} canBeZero />} />
                        <CardBodyField label='Hours:' value={canEdit ?
                          <Input
                            type='text'
                            value={this.state.hours.value}
                            onChange={this.handleHoursChange}
                            validationMessage='Please enter a valid amount'
                            isValid={this.state.hours.isValid}
                            trySubmit={this.state.trySubmit}
                            className='hours'
                          /> : <Fragment>{this.state.hours.value}</Fragment>
                        } />
                        <CardBodyField label='Total Labor:' value={<Price value={this.state.laborTotal} canBeZero />} />
                        <CardBodyField label='Parts:' value={this.state.partsTotal && <Price value={this.state.partsTotal} canBeZero />} />
                        {taxValueMarkup}
                        <hr />
                        <CardBodyField label='Warranty Total:' value={<Price value={this.state.warrantyTotal} canBeZero />} />

                      </Fragment>
                    }
                    {claim.pricingStructure === 0 &&
                      <Fragment>
                        <CardBodyField label='Labor:' value={<Price value={claim.flatRatePrice} canBeZero />} />
                        {taxValueMarkup}
                        <hr />
                        <CardBodyField label='Warranty Total:' value={<Price value={claim.flatRatePrice + this.state.taxValue + (customerCountry === "CANADA" ? this.state.pstTaxValue : 0)} canBeZero />} />
                      </Fragment>
                    }
                  </CardBody>
                </Card>
              }
            </div>
            <Col>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>
                    <FormattedMessage id='warranty.ascInformation' />
                  </span>
                </CardHeader>
                <CardBody>
                  <CardBodyField md={7} claimDataTestId='claim-details-ASC-number' label='ASC #:' value={claim.accountNumber} className='print-left-margin' />
                  <CardBodyField md={7} label='ASC Name:' value={claim.accountName} className='print-left-margin' />
                  <CardBodyField md={7} label='Address:' value={ascAddressMarkup} className='print-left-margin' />
                  <CardBodyField md={7} label='Phone:' value={claim.phone} className='print-left-margin' />
                </CardBody>
              </Card>
              {claim.claimType === 'Customer' &&
                <Card>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.customerInformation' />
                    </span>
                  </CardHeader>
                  <CardBody>
                    <CardBodyField md={7} label='Company Name:' value={claim.customerName} className='print-left-margin' />
                    <CardBodyField md={7} label='Customer Name:' value={claim.customerFirstName + ' ' + claim.customerLastName} className='print-left-margin' />
                    <CardBodyField md={7} label='Customer Order #:' value={claim.customerOrderNumber} className='print-left-margin' />
                    <CardBodyField md={7} label='Address:' value={customerAddressMarkup} className='print-left-margin' />
                    <CardBodyField md={7} label='Phone:' value={claim.customerPhone} className='print-left-margin' />
                  </CardBody>
                </Card>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {claim.claimType === 'Customer' &&
                <Card className='mt-0'>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.repairInformation' />
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Container fluid>
                      <Row>
                        {!(claim.pricingStructure === 0) &&
                          <Fragment>
                            <Col xs='12' md='6' lg='4' className='mb-3 mb-lg-0'>
                              <CardBodyField label='Date Received:' value={<FormattedDate value={claim.dateReceived} />} />
                              <CardBodyField label='Received Via:' value={this.getShippedType(claim.receivedVia)} />
                              <CardBodyField label='Received By:' value={claim.receivedBy} />
                            </Col>
                            <Col xs='12' md='6' lg='4' className='mb-3 mb-lg-0'>
                              <CardBodyField label='Date Repaired:' value={<FormattedDate value={claim.dateRepaired} />} />
                              <CardBodyField label='Repaired By:' value={claim.repairedBy} />
                              {claim.salesOrderId &&
                                <CardBodyField label='Parts Sale Order:' value={claim.salesOrderId} />
                              }
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                              <CardBodyField label='Date Returned:' value={<FormattedDate value={claim.dateReturned} />} />
                              <CardBodyField label='Returned By:' value={claim.returnedBy} />
                              <CardBodyField label='Returned Via:' value={this.getShippedType(claim.returnedVia)} />
                              {claim.returnedViaProvider > 0 &&
                                <CardBodyField label='Provider:' value={this.getShippingProvider(claim.returnedViaProvider)} />
                              }
                              {claim.returnedViaOther &&
                                <CardBodyField label='Provider:' value={claim.returnedViaOther} />
                              }
                              {claim.returnedViaTracking &&
                                <CardBodyField label='Tracking Number:' value={this.getTrackingLink(claim.returnedViaTracking, claim.returnedViaProvider)} />
                              }
                              <CardBodyField label='Work Order #:' value={claim.workOrderNumber} />
                            </Col>
                          </Fragment>
                        }
                        {claim.pricingStructure === 0 &&
                          <Fragment>
                            <Col xs='12' md='6' lg='6' className='mb-3 mb-lg-0'>
                              <CardBodyField label='Date Received:' value={<FormattedDate value={claim.dateReceived} />} />
                              <CardBodyField label='Received By:' value={claim.receivedBy} />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                              <CardBodyField label='Work Order #:' value={claim.workOrder} />
                              {claim.salesOrderId &&
                                <CardBodyField label='Parts Sale Order:' value={claim.salesOrderId} />
                              }
                            </Col>
                          </Fragment>
                        }
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              }
              {claim.photos && claim.photos.length > 0 &&
                <Card>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.photos' />
                    </span>
                  </CardHeader>
                  <CardBody>

                    <Container fluid>

                      <Row className='align-items-center animate-bottom-fade-in my-2'>

                        {photos}

                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              }
              {claim.shipmentFile &&
                <Card>
                  <CardHeader>
                    <span className='text-uppercase'>
                      <FormattedMessage id='warranty.shipping.information' />
                    </span>
                  </CardHeader>
                  <CardBody>

                    <Container fluid>
                      <Row className='align-items-center animate-bottom-fade-in my-2'>
                        <FormattedMessage id='warranty.shipping.batteryNotice' />
                      </Row>
                      <Row className='align-items-center animate-bottom-fade-in my-2'>
                        <FormattedMessage id='warranty.shipping.batteryPrint' />
                      </Row>
                      <Row>
                        <Col md='7'>
                          {claim.sendToMilwaukeeProvider > 0 &&
                            <CardBodyField label='Shipping Provider:' value={this.getShippingProvider(claim.sendToMilwaukeeProvider)} />
                          }
                          {claim.sendToMilwaukeeProviderOther &&
                            <CardBodyField label='Shipping Provider:' value={claim.sendToMilwaukeeProviderOther} />
                          }
                          {claim.sendToMilwaukeeTracking &&
                            <CardBodyField label='Tracking Number:' value={this.getTrackingLink(claim.sendToMilwaukeeTracking, claim.sendToMilwaukeeProvider)} />
                          }
                        </Col>
                      </Row>
                      <Row className='align-items-center animate-bottom-fade-in my-2'>
                        <Button color='primary' onClick={this.getShippingLabel}>
                          <FormattedMessage id='warranty.shipping.labelPrint' />
                        </Button>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              }
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>
                    <FormattedMessage id='warranty.productInformation' />
                  </span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    {claim.claimType === 'Customer' &&
                      <Row>
                        <Col md='6'>
                          <CardBodyField label={this.props.intl.formatMessage({ id: 'general.catalogNumber' }) + ':'} value={claim.catalogNumber} />
                          <CardBodyField label={this.props.intl.formatMessage({ id: 'general.description' }) + ':'} value={claim.toolDescription} />
                          <CardBodyField label={this.props.intl.formatMessage({ id: 'general.serialNumber' }) + ':'} value={claim.serialNumber} />
                          <CardBodyField label={this.props.intl.formatMessage({ id: 'general.purchaseDate' }) + ':'} value={claim.purchaseDate && <FormattedDate value={claim.purchaseDate} />} />
                          {claim.receipt && claim.receipt.id &&
                            <CardBodyField label={this.props.intl.formatMessage({ id: 'general.receipt' }) + ':'} value={<Button className='d-print-none' onClick={this.getReceipt}>
                              <FormattedMessage id='warranty.reviewInformation' />
                            </Button>} />
                          }
                          {!(claim.receipt && claim.receipt.id) && claim.status === 5 && this.isCreator &&
                            <CardBodyField label={this.props.intl.formatMessage({ id: 'general.receipt' }) + ':'} value={
                              <Fragment>
                                <Dropzone
                                  onDrop={this.handleUploadReceipt.bind(this)}
                                  multiple={false}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                      <input className='form-control' {...getInputProps()} />
                                      <Button>Upload</Button>
                                    </div>
                                  )}
                                </Dropzone>
                                <ul>
                                  {this.state.receipt.map(f => <li key={f.name}>{f.name}</li>)}
                                </ul>
                              </Fragment>
                            } />
                          }
                        </Col>
                      </Row>
                    }
                    {partsListMarkup}
                    {commentsMarkup}
                    {approverStatusChangeButtonsMarkup}
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {addPartModal}
        {isImagesLightboxOpen && (
          <Lightbox
            mainSrc={lightboxImages[photoIndex]}
            nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
            prevSrc={lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]}
            onCloseRequest={() => this.setState({
              ...this.state,
              isImagesLightboxOpen: false
            })}
            onMovePrevRequest={() =>
              this.setState({
                ...this.state,
                photoIndex: (photoIndex + lightboxImages.length - 1) % lightboxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                ...this.state,
                photoIndex: (photoIndex + 1) % lightboxImages.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    warrantyClaim: state.warrantyClaim,
    pricing: state.pricing,
    accountNumber: state.account.currentAccount.accountDetails.accountNumber
  };
};

const mapDispatchToProps = dispatch => ({
  getClaim: (id, accountNumber) => dispatch(warrantyClaimActions.getClaim(id, accountNumber)),
  getPrices: (skus, accountNumber) => dispatch(priceActions.getPrices(skus, accountNumber)),
  updateParts: (claimId, parts, accountNumber, comment) => dispatch(warrantyClaimActions.updateParts(claimId, parts, accountNumber, comment)),
  reviewWarrantyClaim: (data, file) => dispatch(warrantyClaimActions.reviewWarrantyClaim(data, file)),
  clearIndexProducts: () => dispatch(productSearchActions.clearIndexProducts()),
  addComment: (claimId, commentText, accountNumber) => dispatch(warrantyClaimActions.addComment(claimId, commentText, accountNumber)),
  getCreditMemo: (creditMemoNumber, accountNumber) => dispatch(warrantyClaimActions.getCreditMemo(creditMemoNumber, accountNumber)),
  getFile: (fileId, warrantyId) => dispatch(warrantyClaimActions.getFile(fileId, warrantyId)),
  toggleDeleted: (id) => dispatch(warrantyClaimActions.toggleDeleted(id))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WarrantyClaimDetails));