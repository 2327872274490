import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Col, Container, FormGroup, Label, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {PageLoader, SearchInput} from '../../components';
import {productSearchActions} from '../../store';
import ProductSearchResult from './ProductSearchResult';

class ProductSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;

    if (prevProps.clear !== this.props.clear) {
      this.clear();
    }
  }
  
  getIndexProduct = () => {
    this.props.getIndexProducts(`?sku=${this.state.sku}`);
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getIndexProduct();
    }
  };
  
  handleSearchClick = () => {
    this.getIndexProduct();
  };
  
  handleSearchChange = (e) => {
    this.setState({
      ...this.state,
      sku: e.target.value
    });
  };

  // Can be called on modal close, "Clear" button press, etc.
  clear = () => {
    this.setState({
      ...this.state,
      sku: ''
    }, () => {
      this.props.clearIndexProducts();
    });    
  };
  
  handleAddClick = (product) => {
    this.props.onAddClick(product); // Pass product details to parent for consumption
  };
  
  render() {
    const { products, totalCount, isLoading } = this.props.productSearch;

    const blankResults = products && (
      <Container fluid>
        <Row>
          <Col>
            <h5 className='my-3'>Product Search Results</h5>
            <ListGroup className='search-list'>
              <ListGroupItem className='text-center search-list-item'>
                <div className='my-4'>
                  <h3>No products found!</h3>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );

    const results = products && products.map((product, index) =>  (
      <ListGroupItem key={`product-${index}`} className='search-list-item'>
        <ProductSearchResult product={product} onAddClick={this.handleAddClick} isProcessing={this.props.isProcessing} configuration={this.props.configuration} />
      </ListGroupItem>
    ));

    const resultsList = isLoading ? <PageLoader/> : (totalCount !== undefined ? (totalCount > 0 ? (
      <Container fluid>
        <Row>
          <Col>
            <h5 className='my-3'>Product Search Results</h5>
            <ListGroup className='search-list'>
              {results}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    ) : blankResults) : null);

    return (
      <Container fluid id='product-search'>
        <Row>
          <Col>
            <FormGroup className='mr-sm-2 mb-sm-0'>
              <Label for='product-number'>Search Products</Label>
              <SearchInput
                searchInputDataTestId='product-search-input'
                searchButtonDataTestId='product-search-button'
                value={this.state.sku}
                onClear={this.clear}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleSearchChange}
                onClick={this.handleSearchClick}
                isSearching={isLoading} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {resultsList}
          </Col>
        </Row>
      </Container>
    );
  }
}

ProductSearch.defaultProps = {
  clear: false
};

ProductSearch.propTypes = {
  onAddClick: PropTypes.func,
  clear: PropTypes.bool,
  isProcessing: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    productSearch: state.productSearch.getIndexProducts
  };
};

const mapDispatchToProps = dispatch => ({
  getIndexProducts: (query, locale) => dispatch(productSearchActions.getIndexProducts(query, locale)),
  clearIndexProducts: () => dispatch(productSearchActions.clearIndexProducts())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductSearch));