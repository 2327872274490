import Enumerable from "linq";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { capitalizeFirstLetter } from "../../helpers/stringHelpers";
import { Button } from "../Button";
import queryString from '../../helpers/queryString';

const ProductOptions = ({product, setSku, currentSku, initialSkuAlias, isLoading, isProductDetail, allowEmployeePurchase}) =>{  
  const [selectedStopGapSku, setSelectedStopGapSku] = useState('');  
  
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  
  const [selectedSku, setSelectedSku] = useState({
    sku: '',
    skuAlias: '',
    images: [],
    nextValidQuantityAllowed: 0,
    lowestQuantityAllowed: 0,
    highestQuantityAllowed: 0,
  });

  let queryStringSku = (queryString.parse()?.q || '').replace(/-/g, '').toUpperCase();

  useEffect(() => {
    if (product) {
      if (!product.options || (product.options && product.options.length === 0)) {
        //we dont have any options so just set the selected sku to the current product
        if (selectedSku.sku !== product.sku) {
          setSelectedSku({sku: product.sku, 
              skuAlias: product.skuAlias, 
              images: product.images,
              nextValidQuantityAllowed: product.nextValidQuantityAllowed,
              lowestQuantityAllowed: product.lowestQuantityAllowed,
              highestQuantityAllowed: product.highestQuantityAllowed,
            });
        }
      } else {
        let normalizedSku = (initialSkuAlias || '').replace(/-/g, '').toUpperCase();  // Need to normalize SKU from URL to determine which options to select
        let optionsEnumerable = Enumerable.from(product.options);
        const ignoreItems = ["lowestquantityallowed", "nextquantityallowed", "highestquantityallowed", "sku", "skualias", "images", "iscustomlogo", "configurable", "width", "height", "length", "isemployeeavailable", "producttranslations", "optiontitle"];
        let optionKeys = optionsEnumerable.selectMany(o=>Object.keys(o).filter(f=>ignoreItems.indexOf(f?.toLowerCase()) < 0 && o[f] !== null)).distinct().toArray();
        if (optionKeys.length === 0) {
          optionKeys.push("optionTitle");
        }
        let optionArr = optionKeys.map(k => {
          return {
            key: k,
            values: optionsEnumerable.select(o=>o[k]).distinct().select(v=>{
              return {
                disabled: false,
                value: v
              };              
            }).toArray()
          };
        })
        setAvailableAttributes(optionArr);

        let validQueryStringSku = queryStringSku?.length && optionsEnumerable.where(x => x.sku === queryStringSku).count() > 0;
        let selectedOption = validQueryStringSku
          ? optionsEnumerable.where(x => x.sku === queryStringSku).firstOrDefault()
          : optionsEnumerable.where(x => x.sku === normalizedSku).firstOrDefault();

        if (selectedOption) {
          let selectedAttributes = optionKeys.reduce((acc, cur)=>{
            acc[cur] = selectedOption[cur];            
            return acc;
          }, {});
          setSelectedAttributes(selectedAttributes);
          if (validQueryStringSku) {
            setSelectedStopGapSku(queryStringSku);
          }
        } else {
          //default things where we only have one option
          let selectedAttributes = optionArr.reduce((acc,cur)=>{
            if(cur.values.length === 1){
              acc[cur.key] = cur.values[0].value;
            } else {
              acc[cur.key] = undefined;
            }            
            return acc;
          }, {});
          setSelectedAttributes(selectedAttributes);
        }
      }
    }
  }, [initialSkuAlias, product]);
  
  const setAttributeValue = (attr, value)=>{
    if (selectedAttributes[attr] == value)
      value = null;
    
    let updatedAttr ={
      ...selectedAttributes,
      [attr]: value
    };

    setSelectedAttributes(updatedAttr);
  }
  
  const isOptionDisabled = useCallback ((attr, val) =>{
    const keysWithValues = Object.keys(selectedAttributes).filter(f=>selectedAttributes[f] && f !== attr);

    let optionsEnumerable = Enumerable.from(product.options);
    let validOptions = optionsEnumerable.where(o=> keysWithValues.every(k=>o[k] === selectedAttributes[k]));

    return !validOptions.any(o => o[attr] === val && (allowEmployeePurchase ? o['isEmployeeAvailable'] : true));
  }, [selectedAttributes, product])
  
  useEffect(() => {
    const optionSelected = (selectedStopGapSku || Object.keys(selectedAttributes).filter(f=>selectedAttributes[f]).length);
    if(selectedSku && (selectedSku.sku !== currentSku) && (optionSelected || isProductDetail)) {
      setSku(selectedSku);
    }
  }, [selectedSku, currentSku]);
  
  useEffect(() => {
    //if we can find a match, then call setSku on the parent

    if (product && product.options && product.options.length && availableAttributes) {      
      let productOptions = Enumerable.from(product.options);
      let options = productOptions.where(x => availableAttributes.every(a => (x[a.key] === selectedAttributes[a.key]) && selectedAttributes[a.key] !== {}));

      if (options.count() > 1 && selectedStopGapSku !== '') {
        options = options.where(x => x.sku === selectedStopGapSku);
      }
      
      if (options.count() === 1) {
        let selectedOption = options.first();

        if (selectedOption.sku !== selectedSku.sku) {
          setSelectedSku({
            sku: selectedOption.sku,
            skuAlias: selectedOption.skuAlias,
            images: selectedOption.images.length > 0 ? selectedOption.images : product.images,
            nextValidQuantityAllowed: selectedOption.nextQuantityAllowed,
            lowestQuantityAllowed: selectedOption.lowestQuantityAllowed,
            highestQuantityAllowed: selectedOption.highestQuantityAllowed,
          })
        }
      } else {
        if ('' !== selectedSku.sku || !selectedSku.images.length) {
          let defaultOption = Enumerable.from(product.options).firstOrDefault();
          setSelectedSku({
            sku: '',
            skuAlias: '',
            images: product.images.length ? product.images : defaultOption.images,
            nextValidQuantityAllowed: 0,
            lowestQuantityAllowed: 0,
            highestQuantityAllowed: 0,
          });
        }
      }
    }    
  }, [selectedAttributes, selectedStopGapSku]);
  
  const optionIsActive = useCallback((key, value)=>{
    return selectedAttributes[key] === value;
  }, [selectedAttributes])

  const getDisplayLabelId = (attrKey) => ['sku', 'optionTitle'].includes(attrKey) ? 'search.options' : `search.${attrKey}`;
  
  if (!product || !product.options || (product.options && product.options.length === 0)) {
    return (<></>);
  } else {    
    let attrMarkup = availableAttributes.map(attr=>{      
      return <Fragment key={`${product.sku}=${attr.key}`}>
        <div className='mt-2'>
          <div className='has-font-size-1'>
            <FormattedMessage id={getDisplayLabelId(attr.key)} defaultMessage={capitalizeFirstLetter(attr.key)} />
          </div>
          <div>
            {
              attr.values.map(v=>(
                 <Button 
                  key={`${product.sku}=${attr.key}-${v.value}`} 
                  outline 
                  color='primary' 
                  className={`mr-2 mt-1 options-button`} 
                  onClick={()=>setAttributeValue(attr.key, v.value)} 
                  active={optionIsActive(attr.key, v.value)} 
                  isDisabled={isLoading || isOptionDisabled(attr.key, v.value)}>
                  {decodeURIComponent(v.value ?? 'None')}
                </Button>
              ))
            }
          </div>
        </div>
      </Fragment>
    });
            
    let selectableSkus = []; 
    
    if (product?.options?.length && availableAttributes?.length && availableAttributes.every(a=>selectedAttributes[a.key])){
      //everything has a value and we have options
      //get all the possible options that match    
      let productOptions = Enumerable.from(product.options);
      selectableSkus = productOptions.where(x=>availableAttributes.every(a=>x[a.key] === selectedAttributes[a.key] && selectedAttributes[a.key])).toArray();
    }
    
    let skuMarkup = '';
    
    if (selectableSkus.length > 1){
      //There are still multiple items for the selected options
      //as a stop gap just show all the skus as clickable items
      let skuOptions = selectableSkus.map((option, index) => {
        return (
          <Button key={`${product.sku}-${option.sku}`} outline color='primary' className='mr-2 mt-1 options-button'
                  onClick={() => setSelectedStopGapSku(option.sku)} active={selectedStopGapSku === option.sku} isDisabled={isLoading}>{option.optionTitle || option.skuAlias}</Button>
        );
      });
      
      skuMarkup =(
        <Fragment>
          <div className='mt-2'>
            <div className='has-font-size-1'><FormattedMessage id='search.options'/></div>
            <div>
              {skuOptions}
            </div>
          </div>
        </Fragment>
      );
    }
    
    return (<>
      {attrMarkup}
      {skuMarkup}
      {selectedSku.skuAlias && product?.options?.length && (
        <Fragment>
        <div className='mt-2'>
          <div className='has-font-size-1'><FormattedMessage id='search.sku'/></div>
          <div className='mt-1 has-font-size-1'>
            {selectedSku.options || selectedSku.skuAlias}
          </div>
        </div>
      </Fragment>
      )}
    </>);
  }  
};


export default ProductOptions;