import Enumerable from 'linq';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import auth from '../../auth';
import { Button, Link, Message, ProductAvailability, ProductPrice, QuantityInput } from '../../components';
import ProductOptions from "../../components/ProductOptions";
import { calculateNewQuantity } from "../../helpers/quantityHelper";
import user from '../../helpers/user';
import productHelper from '../../helpers/productHelper';
import CustomizeItem from '../Customize/components/CustomizeItem';

class ProductPreview extends Component {
  constructor(props) {
    super(props);

    let defaultImage;
    if (props.product.imageUrl) {
      defaultImage = props.product.imageUrl;
    }
    else {
      if (props.product.options) {
        let defaultOption = Enumerable.from(props.product.options).firstOrDefault();
        if (defaultOption && defaultOption.images)
          defaultImage = Enumerable.from(defaultOption.images).firstOrDefault();
      }
    }

    this.state = {
      quantity: props.product.lowestQuantityAllowed || props.product.nextValidQuantityAllowed || 1,
      isAdding: false,
      imageUrl: defaultImage,
      selectedColor: '',
      selectedSize: '',
      sku: props.product.options.length > 0 ? '' : props.product.sku,
      skuAlias: props.product.options.length > 0 ? '' : props.product.skuAlias,
      nextValidQuantityAllowed: props.product.nextValidQuantityAllowed,
      lowestQuantityAllowed: props.product.lowestQuantityAllowed,
      highestQuantityAllowed: props.product.highestQuantityAllowed,
      userChangedQuantity: false,
    };
  }

  handleQuantityChange = (quantity) => {
    this.setState({
      ...this.state,
      userChangedQuantity: true,
      quantity
    });
  };

  handleAddToCartClick = () => {
    this.setState({
      ...this.state,
      isAdding: true
    }, () => {
      let product = {
        ...this.props.product,
        sku: this.state.sku,
        skuAlias: this.state.skuAlias,
        nextValidQuantityAllowed: this.state.nextValidQuantityAllowed,
        lowestQuantityAllowed: this.state.lowestQuantityAllowed,
        highestQuantityAllowed: this.state.highestQuantityAllowed,
      };

      this.props.onAddToCartClick(product, this.state.quantity)
        .then(() => {
          this.setState({
            ...this.state,
            isAdding: false
          });
        });
    });
  };

  handleSelectSku = (option) => {
    if (option) {
      // If the user has changed the quantity, try to keep it the same otherwise default to 1 to avoid issues when skus have min quantities that are multiples of each other
      const currentQuantity = this.state.userChangedQuantity || option.lowestQuantityAllowed === null || option.lowestQuantityAllowed === undefined ? this.state.quantity : option.lowestQuantityAllowed;
      const newQuantity = calculateNewQuantity(currentQuantity, option.lowestQuantityAllowed, option.highestQuantityAllowed, option.nextValidQuantityAllowed || 1);

      this.setState({
        ...this.state,
        quantity: newQuantity,
        sku: option.sku,
        skuAlias: option.skuAlias,
        images: option.images,
        nextValidQuantityAllowed: option.nextValidQuantityAllowed,
        lowestQuantityAllowed: option.lowestQuantityAllowed,
        highestQuantityAllowed: option.highestQuantityAllowed,
      }, () => {
        if (auth.isAuthenticated() && option.sku) {
          let skus = [option.sku];
          this.props.updatePrices(skus);
          this.props.updateAvailability(skus);
        }
      });
    }
  };

  render() {
    const { product, pricing, availability } = this.props;
    const isEmployeeAccount = this.props.isEmployeeAccount;

    const allCustomLogo = product?.isCustomLogo || (product?.options.length && product?.options.every(o => o.isCustomLogo));
    const someCustomLogoOrConfigurable = product?.isCustomLogo || product?.options.some(o => o.isCustomLogo) || product?.configurable || product?.options.some(o => o.configurable);
    const regionLocked = allCustomLogo && !user.isDPLUS();
    const isEmployeeAvailable = product?.isEmployeeAvailable && user.isMilwaukeeToolEmployee();

    let listPrice = user && !user.isMilwaukeeToolEmployee() && auth.isAuthenticated() && user.canPlaceOrders() && !allCustomLogo && (
      <div>
        <span className='has-font-size-1'>
          <FormattedMessage id='pricing.listPrice' />&nbsp;
        </span>
        <span className='has-font-size-1' data-test-id={`product-preview-listprice-${product.sku}`}>
          <del>
            <ProductPrice
              pricing={pricing}
              sku={this.state.sku}
              type='listAmount'
            />
          </del>
        </span>
      </div>
    );

    let yourPrice = !allCustomLogo && (
      <div>
        <div className='has-font-size-2'>
          <FormattedMessage id='pricing.yourPrice' />
        </div>
        <div className='has-font-size-4' data-test-id={`product-preview-price-${product.sku}`}>
          <ProductPrice
            pricing={pricing}
            sku={this.state.sku}
            type='unitAmount'
          />
        </div>
      </div>
    );

    let availabilityMarkup = this.state.sku && !allCustomLogo && (
      <div className='has-font-size-2 font-weight-bold' data-test-id={`product-preview-availability-${product.sku}`}>
        <ProductAvailability
          availability={availability}
          sku={this.state.sku}
        />
      </div>
    );

    let customLogoMarkup = this.props.featureToggles.CustomLogo && (
      <CustomizeItem data-test-id='hi' className='my-3' isCustomLogo={someCustomLogoOrConfigurable} sku={this.state.sku} />
    );

    let restrictedQuantityMessage = this.state.nextValidQuantityAllowed > 0 && this.state.sku !== '' && (
      <Col xs='12' className='order-2 order-lg-1 order-xl-2 text-center mt-2'>
        <div className='font-weight-light text-secondary has-font-size-1'>
          <FormattedMessage id='products.quantityMultiple' values={{ nextValidQuantityAllowed: this.state.nextValidQuantityAllowed }} />
        </div>
      </Col>
    );

    let minQuantityMessage = this.state.lowestQuantityAllowed > 0 && this.state.sku !== '' && (
      <Col xs='12' className='order-2 order-lg-1 order-xl-2 text-center'>
        <div className='font-weight-light text-secondary has-font-size-1'>
          <FormattedMessage id='products.quantityMin' values={{ lowestQuantityAllowed: this.state.lowestQuantityAllowed }} />
        </div>
      </Col>
    );

    let maxQuantityMessage = this.state.highestQuantityAllowed > 0 && this.state.sku !== '' && (
      <Col xs='12' className='order-2 order-lg-1 order-xl-2 text-center'>
        <div className='font-weight-light text-secondary has-font-size-1'>
          <FormattedMessage id='products.quantityMax' values={{ highestQuantityAllowed: this.state.highestQuantityAllowed }} />
        </div>
      </Col>
    );

    const addToCartDisabled = this.state.sku === '' || regionLocked || (!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount && !isEmployeeAvailable);

    let addToCartMarkup = user.canPlaceOrders() && !allCustomLogo && (
      <Container fluid>
        <Row className='justify-content-center'>
          <Col xs='auto' lg='12' xl='auto' className='text-center align-self-center'>
            <div className='d-inline-block'>
              <QuantityInput
                quantity={this.state.quantity}
                incrementQuantity={this.state.nextValidQuantityAllowed}
                minQuantity={this.state.lowestQuantityAllowed}
                maxQuantity={this.state.highestQuantityAllowed}
                onQuantityChange={this.handleQuantityChange}
                dataTestId={this.state.sku} />
            </div>
          </Col>
          {restrictedQuantityMessage}
          {minQuantityMessage}
          {maxQuantityMessage}
          <Col xs='auto' lg='12' xl='auto' className='order-1 order-lg-2 order-xl-1 text-center'>
            <Button data-test-id={`product-preview-addtocart-${product.sku}`} outline color='primary' onClick={this.handleAddToCartClick} isLoading={this.state.isAdding} isDisabled={addToCartDisabled}>
              <FormattedMessage id='search.addToCart' />
            </Button>
          </Col>
        </Row>
      </Container>
    );

    let skuAliasMarkup = product.skuAlias !== product.description && (
      <div data-test-id={`product-preview-sku-${product.sku}`} className='mb-2 mr-lg-3'>{product.skuAlias}</div>
    );

    let messageMarkup = productHelper.getMessageMarkup(product);

    if (regionLocked) {
      let notAvailableMessage = (
        <Fragment>
          <span>Item </span>
          <span className='has-pointer-icon font-weight-bold'>{product.skuAlias}</span>
          <span> not available in your region</span>
        </Fragment>
      );
      messageMarkup = (
        <Row className='mt-3'>
          <Col>
            <Message
              type='info'
              icon='info-circle'
              value={notAvailableMessage}
            />
          </Col>
        </Row>
      );
      yourPrice = (
        <div>
          <div className='has-font-size-2'>
            <FormattedMessage id='pricing.yourPrice' />
          </div>
        </div>
      );
    }

    return (
      <Container className='product-preview'>
        <Row className='align-items-center animate-bottom-fade-in my-2'>
          <Col xs='12' md='auto' className='text-center mx-lg-2 my-md-auto align-self-center product-image'>
            <img src={`/api/v1/images?url=${this.state.imageUrl}`} alt={product.description} />
          </Col>
          <Col sm='6' md='4' lg='3' className='is-color-red mt-3 my-md-auto align-self-center wrap-break-words'>
            <div>
              <Link to={`products/${product.sku}`} locale={this.props.locale} className='product-details-link'>
                {skuAliasMarkup}
                <div data-test-id={`product-preview-name-${product.sku}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.featureToggles?.UseProductOptionTitle && product.optionTitle?.length ? product.optionTitle : product.description) }} className='has-line-height-2 font-weight-bold mr-lg-3' />
              </Link>
            </div>
            <ProductOptions
              isLoading={pricing.isLoading}
              product={this.props.product}
              setSku={this.handleSelectSku}
              currentSku={this.state.sku}
              initialSkuAlias={this.props.product.sku}
              allowEmployeePurchase={!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount && isEmployeeAvailable}
            />
          </Col>
          <Col xs='auto' lg='5' xl='2' className={allCustomLogo ? 'd-none' : 'mx-auto mx-lg-0 pt-md-3 pt-lg-0 pr-xl-0 mt-3 mx-sm-auto my-md-auto align-self-center'}>
            {listPrice}
            {yourPrice}
            {availabilityMarkup}
          </Col>
          <Col xs='12' lg className='mt-4 my-xl-auto'>
            {customLogoMarkup}
            {addToCartMarkup}
          </Col>
        </Row>
        {messageMarkup}
      </Container>
    );
  }
}

ProductPreview.propTypes = {
  product: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  availability: PropTypes.object.isRequired,
  onAddToCartClick: PropTypes.func,
  updatePrices: PropTypes.func,
  updateAvailability: PropTypes.func,
  locale: PropTypes.string,
  featureToggles: PropTypes.object.isRequired,
  isEmployeeAccount: PropTypes.bool
};

export default injectIntl(ProductPreview);